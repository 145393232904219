import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import MovingContainer from './WidgetMoving.js'

import './App.css';
class App extends Component{
    intervalID;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            locationData: null,
            locationId: window.location.pathname.substr(1)
        };
    }

    componentDidMount()
    {
    }

    componentWillUnmount()
    {
    }


    render() {
        return (
            <Container fluid={true} className="App">
                <MovingContainer/>
            </Container>
        );
    }
}

export default App;
